@import '../../../data/styles.css';

#aboutme-container .personal-description{
	margin-left: 12%;
	margin-bottom: 1%;
	margin-top: 5%;
	margin-right: 5%;

}

.homepage-socials {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 1%;
}

.homepage-social-icon {
	color: var(--background-color);
}
.third-party-icon{
	max-width: 85% ;
	padding-bottom: 15%;
	background-color: var(--background-color);
	border-radius: 10px !important;


}

.profiles-container, .qualities-container{
	padding: 20px !important;
	border-radius: 30px !important;
	height: 180px;
	background-color:  var(--primary-color)!important;
	color : var(--background-color) !important;
}
.personal-comp{
	width: 75%;
}
.homepage-socials a{
	width: 50%;
}
.section-title{
	padding-top: 50px !important;
}

#qualities-profiles-container{
	justify-content: center;
	/* margin-top: 0 ; */
	
}
#qualities-profiles-container .item{
	margin-right: 5%;
	margin-left: 5%;
	margin-bottom: 5%;

	min-width: 300px ; 
}
