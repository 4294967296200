@import "../../data/styles.css";


.homepage-aboutme, .homepage-projects, .homepage-contact{
	background-color:  var(--background-color) !important;
	/* background: linear-gradient(#F3F1E5 0%, #FFFFFF 100%) !important; */

}
.homepage-works,.homepage-skills{
	background-color:  var(--alternating-bg-color)!important;
	/* background: linear-gradient(#F3F1E5 0%, #FFFFFF 100%) !important; */
}
.content-wrapper{
	margin-left: 20%;
	margin-right: 20%;
}

