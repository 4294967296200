@import "../../../data/styles.css";
  

.vertical-timeline::before {
	/* this is the vertical line */
  
	background: var(--primary-color) !important;
}
.vertical-timeline-icon{
	margin-left: 20% !important;
}
.read-more-button{
	margin-left: auto !important;
}
.work-date{
	align-items: center;
	
}