
@import "../../../data/styles.css";
/* 
.nav-container {
    display: flex;
    flex-direction: column;
    align-items: center;
} */

.navbar {
    display: flex;
    flex-direction: column; 
    align-items: center;
    position: fixed;
    top: 50%; 
    left: 20px; 
    transform: translateY(-50%); 
    z-index: 999;
    background:none;
}


/* MUI takes precendence so use important */
.nav-item {
    width: 48px !important;
    height: 48px !important;
    transition: all 0.3s ease-in-out !important;
/*    margin-bottom: 10px !important; /* mb: 3 equivalent */

}

.nav-item:hover {
    background-color: var(--secondary-color) !important;
	transform: scale(1.2); 
    color: var(--primary-color) !important;

}

.active .nav-item {
    background-color: var(--primary-color) !important;
	transform: scale(1.2); 
    color: white !important;
}
#bottom-nav {
    display: none ;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #DAE0F2;
    height: 70px ;
    justify-content: space-evenly;
    z-index: 1; /* Ensure it appears on top */

}
  

@media (max-width: 900px) {
    .navbar {
        display: none;
    }
    
    #bottom-nav {
        display: flex;

    }


    .navbar.open {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 60px;
        left: 20px;
        background: white;
        padding: 10px;
        border-radius: 5px;
    }
}