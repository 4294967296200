@import "../../../data/styles.css";



.work-position, .work-summary{
	color : var(--secondary-color);
}
.workProfile-content .skill-button{
	padding: 0% !important;
	background-color: var(--tertiary-color);
}
.workProfile-content .languages,.workProfile-content .tools,.workProfile-content .frameworks,.workProfile-content .databases {
	/* padding: 0% !important; */
	background-color: white;
}
.workProfile-content .skill-icon{
	margin-top: 2%; 
}
.workProfile-content .skills-title{
	font-size: large;
	color: var(--primary-color);
}
.workProfile-content{
	color: var(--primary-color);
}

.work-content-heading{
	color: var(--secondary-color);
}
.workProfile-content .skill-name{
	display: none;
}
