.hex-grid {
    align-items: center !important;
    justify-content: center;
    display: flex !important;
    /* background: #41C7AC !important; */
}

.hex-grid:hover{
    /* transform: scale(1.2) ;  */
    transition-timing-function: ease-in;
    background: #f7f2cb !important;
    /* color:var(--) !important; */
}
.hex-grid .container{
    align-items: center ;
    flex-direction: column;

}

.hex-grid .skill-icon{
    font-size: 2.6rem;
}

.skills-legend{
    justify-content: center;
    flex-wrap: wrap;
}
.skills-legend .grid-item{
    margin: 10px;
}

.languages{
    background: #ffb897;
}
.frameworks{
    background: #ffd1a3;
}
.databases{
    background: #fce4c0;
}
.tools{
    background: #f3f1e5 ;
}

