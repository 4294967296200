@import "../../../data/styles.css";

.tech-title{
	padding-left: 4% !important;
}
.tech-stack-container{
	padding: 2%;
}
.tech-button{
	padding: 0px !important;
	border-radius: 20px !important;
}
.project-container{
	border-radius: 10px !important;
	/* background: var(--primary-color) !important; */
	color: var(--primary-color) !important;
	border-color: var(--primary-color) !important;
}
.project-title{
	color: var(--primary-color);
}
.tech-stack .skill-name{
	display: none;
}
.project-container .languages,.project-container .tools,.project-container .frameworks,.project-container .databases {
	/* padding: 0% !important; */
	background-color: var(--background-color);
}