:root {
	/* ------- colors ------- */
	--primary-color: #131842;
	--font-color: #000000;
	--background-color: #F3F1E5;
	--alternating-bg-color: #F4f9f6;
	--link-color: #14b8a6;
	/* ---------------------- */

	/* ------- fonts ------- */
	--primary-font: "Open Sans", sans-serif;
	/* --------------------- */
}
